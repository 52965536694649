import * as React from "react";
import styled from "styled-components";

const ThankYouStyles = styled.div`
  padding: 80px;
  p {
    color: ${(props) => props.theme.text.paragraph};
  }
  .thank-you-message-container {
    width: 33.333%;
    margin: auto;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    .thank-you-message-container {
      width: 100%;
    }
  }
`;

const ThankYouPage = () => (
  <ThankYouStyles>
    <div className="thank-you-message-container">
      <h1>Thank you!</h1>
      <p>Your submission has been received</p>
      <p>
        <a href="/">Click Here to return to home page</a>
      </p>
    </div>
  </ThankYouStyles>
);

export default ThankYouPage;
